/*********************************
 * ACF GOOGLE MAP FUNCTION
*********************************/
(function($) {
    console.log("ACF Google Maps has loaded.");
  /*
  *  render_map
  *
  *  This function will render a Google Map onto the selected jQuery element
  *
  *  @type    function
  *  @date    8/11/2013
  *  @since   4.3.0
  *
  *  @param   $el (jQuery element)
  *  @return  n/a
  */

  function render_map( $el, multi ) {

      // var
      var $markers = $el.find('.marker');

      // vars
      var args = {
          zoom        : 16,
          center      : new google.maps.LatLng(0, 0),
          mapTypeId   : google.maps.MapTypeId.ROADMAP,
          scrollwheel : false,
          styles: [
            {elementType: 'geometry', stylers: [{color: '#fffdf9'}]},
            {elementType: 'labels.text.stroke', stylers: [{color: '#fffdf9'}]},
            {elementType: 'labels.text.fill', stylers: [{color: '#bf9863'}]},
            {
              featureType: 'administrative.locality',
              elementType: 'labels.text.fill',
              stylers: [{color: '#bf9863'}]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [{color: '#bf9863'}]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [{color: '#fffdf9'}]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [{color: '#bf9863'}]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [{color: '#f0e6dc'}]
            },
            {
              featureType: 'road',
              elementType: 'geometry.stroke',
              stylers: [{color: '#f0e6dc'}]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [{color: '#fff'}]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [{color: '#d8d8d8'}]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [{color: '#d8d8d8'}]
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry.fill',
                stylers: [{color: '#fff'}]
              },
            {
              featureType: 'road.highway',
              elementType: 'labels.icon',
              stylers: [{ Hue: "#6b6e96" },
              { saturation: -60 },
              { lightness : 5 }]
            },

            {
              featureType: 'transit',
              elementType: 'geometry',
              stylers: [{color: '#d8d8d8'}]
            },
            {
              featureType: 'transit.station',
              elementType: 'labels.text.fill',
              stylers: [{color: '#bf9863'}]
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [{color: '#a3dddd'}]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [{color: '#bf9863'}]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.stroke',
              stylers: [{color: '#a3dddd'}]
            }
          ]
      };

      // create map               
      var map = new google.maps.Map( $el[0], args);

      // add a markers reference
      map.markers = [];

      // add markers
      $markers.each(function(index){

          add_marker( $(this), map, multi, index );

      });

  }

  /*
  *  add_marker
  *
  *  This function will add a marker to the selected Google Map
  *
  *  @type    function
  *  @date    8/11/2013
  *  @since   4.3.0
  *
  *  @param   $marker (jQuery element)
  *  @param   map (Google Map object)
  *  @return  n/a
  */

  function add_marker( $marker, map, multi, index ) {

      if(multi) {

          var a = $marker.attr('data-add');

          var geocoder = new google.maps.Geocoder();

          geocoder.geocode({ 'address' : a }, function( results, status ){
              
              // validate
              if( status != google.maps.GeocoderStatus.OK ) {
                  
                  console.log('Geocoder failed due to: ' + status);
                  
              } else if( !results[0] ) {
                  
                  console.log('No results found');

              } else {

                  // create marker
                  var letter = String.fromCharCode("A".charCodeAt(0) + index);
                  var marker = new google.maps.Marker({
                      position    : results[0].geometry.location,
                      map         : map,
                    //   icon        : "https://maps.google.com/mapfiles/marker" + letter + ".png",
                      icon        : '/wp-content/themes/goodmancampbell2020/images/location.svg',
                      animation   : google.maps.Animation.DROP,
                  });

                  // add to array
                  map.markers.push( marker );

                  // if marker contains HTML, add it to an infoWindow
                  if( $marker.html() )
                  {
                      // create info window
                      var infowindow = new google.maps.InfoWindow({
                          content     : $marker.html()
                      });

                      // show info window when marker is clicked
                      google.maps.event.addListener(marker, 'click', function() {

                          infowindow.open( map, marker );

                      });
                  }

                  // center map
                //   center_map( map );
              }
              
          });

      } else {
        
          // var
          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
          var store_address = $marker.attr('data-address');
          var store_link = $marker.attr('data-link');
          var store_title = $marker.attr('data-title');
          var store_city = $marker.attr('data-city');
          var store_state = $marker.attr('data-state');
          var store_tel = $marker.attr('data-tel');
          var store_fax = $marker.attr('data-fax');
          var store_excerpt = $marker.attr('data-excerpt');
          var store_image = $marker.attr('data-image');


          // create marker
          var marker = new google.maps.Marker({
              position    : latlng,
              map         : map,
              icon        : '/wp-content/themes/goodmancampbell2020/images/location.svg',
              store_address   :  store_address,
              store_link   :  store_link,
              store_title   :  store_title,
              store_city   :  store_city,
              store_state   :  store_state,
              store_tel   :  store_tel,
              store_fax   :  store_fax,
              store_excerpt   :  store_excerpt,
              store_image   :  store_image,


          });

          // add to array
          map.markers.push( marker );

          // if marker contains HTML, add it to an infoWindow
          if( $marker.html() )
          {
              // create info window
              var infowindow = new google.maps.InfoWindow({
                  content     : $marker.html()
              });

              // show info window when marker is clicked
              google.maps.event.addListener(marker, 'click', function() {

                //   infowindow.open( map, marker );
                var address = marker.get('store_address');
                var link = marker.get('store_link');
                var title = marker.get('store_title');
                var city = marker.get('store_city');
                var state = marker.get('store_state');
                var tel = marker.get('store_tel');
                var fax = marker.get('store_fax');
                var excerpt = marker.get('store_excerpt');
                var image = marker.get('store_image');
                // Testing content coming through data attributes. 
                // console.log(address, link, title, city, state, tel, fax, excerpt, image);

                if (!$('.multi-location-map-overlay').hasClass('show-map-overlay')) {
                    $('.multi-location-map-overlay').addClass('show-map-overlay');
                }

                $('.map-overlay-content').hide().html(
                    `<h6 class="overlay-city">${city}, ${state}</h6>
                     <h3 class="overlay-title"><a href="${link}">${title}</a></h3>
                     <p class="overlay-address">${address}</p>
                     <p class="overlay-number"><span>Tel:</span> <a href="tel:+${tel}"> ${tel}</a></p>
                     <p class="overlay-number"><span>Fax:</span> ${fax}</p>
                     <p class="overlay-excerpt">${excerpt}</p>
                     <img class="overlay-image" src="${image}"/>
                     <a class="more-link overlay-link" href="${link}">See Location Details</a>
                    `
                ).fadeIn('slow');

              });
              
              google.maps.event.addListener(map, "click", function(event) {
                if ($('.multi-location-map-overlay').hasClass('show-map-overlay')) {
                    $('.multi-location-map-overlay').removeClass('show-map-overlay');
                }
            });
          }

          // center map
          center_map( map );

      }
     
  }

  /*
  *  center_map
  *
  *  This function will center the map, showing all markers attached to this map
  *
  *  @type    function
  *  @date    8/11/2013
  *  @since   4.3.0
  *
  *  @param   map (Google Map object)
  *  @return  n/a
  */

  function center_map( map ) {

      // vars
      var bounds = new google.maps.LatLngBounds();

      // loop through all markers and create bounds
      // $.each( map.markers, function( i, marker ){

      //     var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

      //     bounds.extend( latlng );

      // });

      // only 1 marker?
      if( map.markers.length == 1 )
      {
          // set center of map
          $.each( map.markers, function( i, marker ){

            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
  
            bounds.extend( latlng );
  
        });
          map.setCenter( bounds.getCenter() );
          // map.setCenter(-86.197320, 39.907590, 12);
          map.setZoom( 12 );
      }
      else
      {
        $.each( map.markers, function( i, marker ){
          if(marker.store_state !== "Wisconsin") {
            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
          }


      });
          // fit to bounds
          map.fitBounds( bounds );
          // map.setCenter(-86.158021, 39.768427, 11);
          map.setZoom(11);

      }

  }

  jQuery(function() {

      $('.acf-map').each(function(){

          render_map( $(this), false );

      });

  });

})(jQuery);

/************************************
 * END ACF GOOGLE MAP FUNCTION
*************************************/