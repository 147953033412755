var didScroll;
var lastScrollTop = 0;
var delta = 10;
var navbarHeight = $(".navigation-wrapper").outerHeight(); //86 to 56

jQuery(window).on('scroll', function($) {
  // console.log("The page is scrolling");

  didScroll = true;

  if(window.location.pathname.indexOf('/glossary/') > -1) {
    glossaryBarScroll();
  }
  
});

setInterval(function() {
  if (didScroll) {
    hasScrolled();
    didScroll = false;
  }
}, 250);


function hasScrolled() {

  if(jQuery(window).outerWidth() > 991) {

    var st = jQuery(this).scrollTop();
  
    if (Math.abs(lastScrollTop - st) <= delta) return;
  
    if (st > lastScrollTop && st > navbarHeight) {
      jQuery(".navigation-wrapper").addClass("small");
      jQuery(".navigation-banner").addClass('small');
      jQuery(".glossary-breadcrumb").addClass("small");
    } else {
      if (st + jQuery(window).height() < jQuery(document).height()) {
        jQuery(".navigation-wrapper").removeClass("small");
        jQuery(".navigation-banner").removeClass('small');
        jQuery(".glossary-breadcrumb").removeClass("small");
      }
    }
  
    lastScrollTop = st;
  }
}

function glossaryBarScroll() {
  var navbarHeightTest = jQuery(".navigation-bar").outerHeight();
  var glossaryHeight = jQuery(".breadcrumb-placeholder").outerHeight();
  var scrollTopTest = jQuery(window).scrollTop(),
    elementOffsetTest = jQuery(".breadcrumb-placeholder").offset().top,
    distanceTest = elementOffsetTest - scrollTopTest;

  if (distanceTest <= 180) {
    jQuery(".glossary-breadcrumb").addClass("fixed");
  } else {
    jQuery(".glossary-breadcrumb").removeClass("fixed");
  }
}
