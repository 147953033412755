// import $ from "jquery";
// window.$ = window.jQuery = $;

jQuery(function(){

    const galleryWrapper = $('.horizontal-gallery-wrapper');

    if(galleryWrapper.length > 0) {

        let gallery = galleryWrapper.find('.horizontal-gallery');
        let galleryImage = gallery.find('img');
        let rowNum = gallery.attr('data-rows') ? gallery.attr('data-rows') : 0;
        let rowHeight = gallery.attr('data-row-height') ? gallery.attr('data-row-height') : 120;
        let overflow = gallery.attr('data-horizontal') ? gallery.attr('data-horizontal') : 0;
        let totalWidth = 0;


        $(window).on('load', function() {
            if(overflow) {
                gallery.justifiedGallery({
                    rowHeight: rowHeight,
                    maxRowsCount: rowNum,
                    // maxRowHeight:rowHeight,
                    margins:1,
                    lastRow:'nojustify',
                })
                .on('jg.complete', function() {
                    setTimeout(function() {
                        galleryImage.each(function() {
                            let imageWidth = $(this).width();
                            totalWidth += imageWidth;
                        });
                        gallery.parent().css('width', Math.ceil(totalWidth/rowNum)); 
                    }, 250)
                })
            } else {
                gallery.justifiedGallery({
                    rowHeight: rowHeight,
                    maxRowsCount: rowNum,
                    // maxRowHeight:rowHeight,
                    margins:1,
                    lastRow:'justify',
                })
            }

        })

        
    
        // console.log(totalWidth/rowNum);
        


        
    }

})