import $ from "jquery";
window.$ = window.jQuery = $;

jQuery(function() {
  $(".image-slider-block").slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  var headerContentSlider = {
    slidesToShow: 1,
    infinite: true,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.header-slider'
  };

  var headerSlider = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.header-content-slider',
    prevArrow: $('.header-slider-prev'),
    nextArrow: $('.header-slider-next'),
    focusOnSelect: true,
    autoplay: true,
    autoplaySpeed: 7000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerMode:true,
          centerPadding:0,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          centerMode:true,
          centerPadding:0,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  $('.header-content-slider').slick(headerContentSlider);
  $('.header-slider').slick(headerSlider);

  var slickSlideWidth = $('.header-slider .slick-slide').width();
  if($(window).outerWidth() > 1199) {
    $('.header-image-slider-wrapper .header-slider-wrapper .header-slider .slick-track').css('left',slickSlideWidth);
  }

  $(window).resize(function() {
    if($(this).outerWidth() > 1199) {
    $('.header-image-slider-wrapper .header-slider-wrapper .header-slider .slick-track').css('left',slickSlideWidth);
    } else {
    $('.header-image-slider-wrapper .header-slider-wrapper .header-slider .slick-track').css('left','0px');

    }
  })

  var mobileHeaderSlider = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight:true,
    prevArrow: $('.mobile-header-slider-prev'),
    nextArrow: $('.mobile-header-slider-next'),
  };

  $('.mobile-header-slider').slick(mobileHeaderSlider);

  //TESTIMONIAL SLIDER

  var testimonialSlider = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    prevArrow: $('.testimonial-prev-arrow'),
    nextArrow: $('.testimonial-next-arrow'),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode:true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          // centerPadding: '20px',

        }
      },
      {
        breakpoint: 575,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          // vertical: true,
          // verticalSwiping: true,
          // adaptiveHeight: true,
          // centerMode: true,
          // centerPadding: "20%",
          // rows:2
        }
      }
    ]
  }
  $(".testimonial-slider").slick(testimonialSlider);
  $('.testimonial-slider .see-more').on('click', function() {

    let parent = $(this).parent();
    let cardExcerpt = $(this).prevAll('.card-excerpt');
    let cardExcerptShow = $(this).prevAll('.card-excerpt-reviel');

    parent.toggleClass('adjust-height');
    cardExcerpt.toggleClass('hide');
    cardExcerptShow.toggleClass('show');
    $(this).toggleClass('see-less');
  });
  // if($(window).outerWidth() > 575) {
    // var maxHeight = -1;
  //   $('.testimonial-slider .slick-slide').each(function() {
  //     if ($(this).height() > maxHeight) {
  //       maxHeight = $(this).height();
  //     }
  //   });
  //   $('.testimonial-slider .slick-slide').each(function() {
  //     if ($(this).height() < maxHeight) {
  //       $(this).css('margin', Math.ceil((maxHeight-$(this).height())/2) + 'px 0');
  //     }
  //   });
  // }
});
