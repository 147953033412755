/*******************************
 * Detect IE version
*******************************/
function GetIEVersion() {
  var sAgent = window.navigator.userAgent;
  // If IE, return version number.
  if (!!navigator.userAgent.match(/MSIE 9\./)) {
    return 9;
  } else if(!!navigator.userAgent.match(/MSIE 10\./)) {
    return 10;
  } else if (!!navigator.userAgent.match(/Trident\/7\./)) {
    return 11;
  } else if(!!navigator.userAgent.match(/Edge\/12\./)) {
    return 12;
  } else if(!!navigator.userAgent.match(/Edge\/13\./)) {
    return 13;
  } else if(!!navigator.userAgent.match(/Edge\/14\./)) {
    return 14;
  } else {
    return 0; //It is not IE
  }
}

jQuery(document).ready(function($) {
  /* Set user agent on html element for IE specific styles */
  console.log("Detect IE Working");
  var doc = document.documentElement;
  var ie9 = false;
  var ie10 = false;
  var ie11 = false;
  var ie12 = false;
  var ie13 = false;
  var ie14 = false;
  var ieVersion = GetIEVersion();

  if (ieVersion === 9) {
    ie9 = true;
  } else if (ieVersion === 10) {
    ie10 = true;
  } else if (ieVersion === 11){
    ie11 = true;
  } else if (ieVersion === 12) {
    ie12 = true;
  } else if (ieVersion === 13) {
    ie13 = true;
  } else if (ieVersion === 14) {
    ie14 = true;
  }

  if (ie9) {
    $("body").addClass("ie9");
  } else if (ie10) {
    $("body").addClass("ie10");
  } else if (ie11) {
    $("body").addClass("ie11");
  } else if (ie12) {
    $("body").addClass("ie12");
  } else if (ie13) {
    $("body").addClass("ie13");
  } else if (ie14) {
    $("body").addClass("ie14");
  }
});

/*******************************
 * End Detect IE version
*******************************/


